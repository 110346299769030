<template>
    <div>
        <el-tabs v-model="activeName" @tab-click="handleClick" v-loading="loading">
            <el-tab-pane label="默认配置" name="system">
                <el-form :label-position="labelPosition" status-icon label-width="100px" :rules="systemrules" ref="system" :model="system" >
                    <!-- <el-form-item v-for="(item, index) in formData.data" :label="item.title" :key="item.id">
                        <el-input v-model="item.value" :placeholder="'请输入'+item.title"></el-input>
                    </el-form-item> -->
                    <el-form-item  label="logo" prop="logo">
                        <el-upload class="avatar-uploader" :action="this.common.url+'api/imgFile'" :show-file-list="false" :on-success="logoSuccess" :before-upload="logoUpload"><img v-if="system.logo" :src="this.common.url+system.logo" class="avatar"><i v-else class="el-icon-plus avatar-uploader-icon"></i></el-upload>
                    </el-form-item>
                    <el-form-item  label="税源地logo" prop="service_logo">
                        <el-upload class="avatar-uploader" :action="this.common.url+'api/imgFile'" :show-file-list="false" :on-success="serviceLogoSuccess" :before-upload="serviceLogoUpload"><img v-if="system.service_logo" :src="this.common.url+system.service_logo" class="avatar"><i v-else class="el-icon-plus avatar-uploader-icon"></i></el-upload>
                    </el-form-item>
                    <!-- <el-form-item  label="推广员费率" prop="rate">
                        <el-input @input="rate" class="no-number" type="number" v-model.number="system.rate" placeholder="请输入推广员费率"></el-input>
                    </el-form-item> -->
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit('system')">保存</el-button>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="支付配置" name="pay">
                <el-form :label-position="labelPosition" :rules="payrules" label-width="130px" ref="pay" :model="pay" >
                    <el-form-item  label="支付宝应用appid" prop="appid">
                        <el-input type="text" v-model="pay.appid" placeholder="请输入支付宝应用appid"></el-input>
                    </el-form-item>
                    <el-form-item  label="支付宝私钥" prop="rsa_private_key">
                        <el-input type="textarea" v-model="pay.rsa_private_key" placeholder="请输入支付宝私钥"></el-input>
                    </el-form-item>
                    <el-form-item  label="应用公钥证书" prop="app_cert_path">
                        <Upload accept=".crt" :default-file-list="app_cert_path" :on-success="appcertpathsuccess" :action="this.common.url+'api/imgFile'">
                            <Button icon="ios-cloud-upload-outline">Upload files</Button>
                        </Upload>
                    </el-form-item>
                    <el-form-item  label="支付宝公钥证书" prop="alipay_cert_path">
                        <Upload accept=".crt" :default-file-list="alipay_cert_path" :on-success="alipaycertpathsuccess" :action="this.common.url+'api/imgFile'">
                            <Button icon="ios-cloud-upload-outline">Upload files</Button>
                        </Upload>
                    </el-form-item>
                    <el-form-item  label="支付宝根证书" prop="alipay_root_cert_path">
                        <Upload accept=".crt" :default-file-list="alipay_root_cert_path" :on-success="alipayrootcertpathsuccess" :action="this.common.url+'api/imgFile'">
                            <Button icon="ios-cloud-upload-outline">Upload files</Button>
                        </Upload>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit('pay')">保存</el-button>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="畅联配置" name="apipay">
                <el-form :label-position="labelPosition" status-icon label-width="100px" :rules="apipayrules" ref="apipay" :model="apipays" >
                    <el-form-item  label="畅联appid" prop="appid">
                        <el-input type="text" v-model="apipays.appid" placeholder="请输入畅联appid"></el-input>
                    </el-form-item>
                    <el-form-item  label="畅联私钥" prop="privKey">
                        <el-input type="textarea" v-model="apipays.privKey" placeholder="请输入畅联私钥"></el-input>
                    </el-form-item>
                    <el-form-item  label="代付支付宝账户编号" prop="apy_account_id">
                        <el-input type="text" v-model="apipays.apy_account_id" placeholder="请输入代付支付宝账户编号"></el-input>
                    </el-form-item>
                    <!-- <el-form-item  label="代付银行卡账户编号" prop="bank_account_id">
                        <el-input type="text" v-model="apipays.bank_account_id" placeholder="请输入代付银行卡账户编号"></el-input>
                    </el-form-item> -->

                    <el-form-item>
                        <el-button type="primary" @click="onSubmit('apipay')">保存</el-button>
                    </el-form-item>
                </el-form> 
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
    import {request} from "@/library/request.js";
    export default {
        data() {
            return {
                activeName: 'system',
                labelPosition:"right",
                loading:false,
                app_cert_path:[],
                alipay_cert_path:[],
                alipay_root_cert_path:[],
                system:{
                    logo:null,
                    service_logo:null,
                    // rate:0
                },
                pay:{
                    appid:null,
                    rsa_private_key:null,
                    app_cert_path:null,
                    alipay_cert_path:null,
                    alipay_root_cert_path:null
                },
                apipays:{
                    appid:null,
                    apy_account_id:null,
                    bank_account_id:null,
                    privKey:null
                },
                systemrules:{
                    logo:[
                        {required: true, message: '请上传logo', trigger: 'blur'}
                    ],
                    service_logo:[
                        {required: true, message: '请上传税源地logo', trigger: 'blur'}
                    ],
                    // rate:[
                    //     {required: true, message: '请输入推广员费率', trigger: 'blur'},
                    //     {type:"number",min:0,max: 100, message: '推广员费率在0到100之间', trigger: 'blur'}
                    // ],
                },
                payrules:{
                    appid:[
                        {required: true, message: '请输入支付宝应用appid', trigger: 'blur'},
                    ],
                    rsa_private_key:[
                        {required: true, message: '请输入支付宝私钥', trigger: 'blur'}
                    ],
                    app_cert_path:[
                        {required: true, message: '请上传应用公钥证书', trigger: 'blur'}
                    ],
                    alipay_cert_path:[
                        {required: true, message: '请上传支付宝公钥证书', trigger: 'blur'}
                    ],
                    alipay_root_cert_path:[
                        {required: true, message: '请上传支付宝根证书', trigger: 'blur'}
                    ],
                },
                apipayrules:{
                    appid:[
                        {required: true, message: '请输入畅联appid', trigger: 'blur'}
                    ],
                    privKey:[
                        {required: true, message: '请输入畅联私钥', trigger: 'blur'}
                    ],
                    apy_account_id:[
                        {required: true, message: '请输入代付支付宝账户编号', trigger: 'blur'}
                    ],
                    // bank_account_id:[
                    //     {required: true, message: '请输入代付银行卡账户编号', trigger: 'blur'},
                    // ],
                },
            };
        },
        methods: {
            handleClick(tab, event) {
                this.getList()
            },
            getList(){
                this.loading = true
                request({
                    url:"config/detail",
                    method:"get",
                    params:{group:this.activeName},
                }).then(response  => {
					this.$Loading.finish();
					this.loading = false
                    if(response.data){
                        if(this.activeName=="system"){
                           this.system = response.data
                        }else if(this.activeName == "pay"){
                            let app_cert_path = []
                            app_cert_path.push({name:"应用公钥证书",url:this.common.url+response.data.app_cert_path})
                            this.app_cert_path = app_cert_path

                            let alipay_cert_path = []
                            alipay_cert_path.push({name:"支付宝公钥证书",url:this.common.url+response.data.alipay_cert_path})
                            this.alipay_cert_path = alipay_cert_path

                            let alipay_root_cert_path = []
                            alipay_root_cert_path.push({name:"支付宝根证书",url:this.common.url+response.data.alipay_root_cert_path})
                            this.alipay_root_cert_path = alipay_root_cert_path

                            this.pay = response.data
                        }else if(this.activeName == "apipay"){
                            this.apipays = response.data
                        }
                    }
                    
				}).then(err => {
					if(err){
						this.loading = false
						this.$Loading.error();
						this.$Message.error("系统异常，请稍后重试")
					}
				})
            },
            onSubmit(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.save()
                    } else {
                        return false;
                    }
                });
            },
            save(){
                this.loading = true
                let data = {}
                if(this.activeName=="system"){
                     data.param = this.system;
                }else if(this.activeName == "pay"){
                    data.param = this.pay;
                }
                else if(this.activeName == "apipay"){
                    data.param = this.apipays;
                }
                
                data.group = this.activeName

                return request({
                    url:'config/detail',
                    method:"post",
                    params:data
                }).then(response  => {
                    this.loading = false
                    if(response.code==200){
                        this.$Message.success({content:response.msg})
                    }else{
                        this.$Message.error({
                            content:response.msg,
                            duration: 3
                        })
                    }
                }).then(err => {
                    if(err){
                        this.loading = false
                        this.$Message.error("系统异常，请稍后重试")
                    }
                })
            },
            logoUpload(file){
                const isJPG = file.type === 'image/jpeg';
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isJPG) {
                    this.$message.error('上传logo只能是 JPG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传logo大小不能超过 2MB!');
                }
                return isJPG && isLt2M;
            },
            serviceLogoUpload(file){
                const isJPG = file.type === 'image/jpeg';
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isJPG) {
                    this.$message.error('上传税源地logo只能是 JPG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传税源地logo大小不能超过 2MB!');
                }
                return isJPG && isLt2M;
            },
            logoSuccess(res, file) {
                this.system.logo =res.data.image;
            },
            serviceLogoSuccess(res, file) {
                this.system.service_logo =res.data.image;
            },
            appcertpathsuccess(response, file, fileList){
                let app_cert_path = []
                app_cert_path.push({name:file.name,url:this.common.url+response.data.image})
                this.app_cert_path = app_cert_path
                this.pay.app_cert_path = response.data.image;
            },
            alipaycertpathsuccess(response, file, fileList){
                let alipay_cert_path = []
                alipay_cert_path.push({name:file.name,url:this.common.url+response.data.image})
                this.alipay_cert_path = alipay_cert_path
                this.pay.alipay_cert_path = response.data.image;
            },
            alipayrootcertpathsuccess(response, file, fileList){
                let alipay_root_cert_path = []
                alipay_root_cert_path.push({name:file.name,url:this.common.url+response.data.image})
                this.alipay_root_cert_path = alipay_root_cert_path
                this.pay.alipay_root_cert_path = response.data.image;
            },
            rate(val){
                this.system.rate = val-0
            }
        },
        mounted(){
            this.getList()
        },
        // watch:{
        //     'system.rate': {
        //         handler(newVal,oldVal){
        //             console.log(newVal,oldVal)
        //             this.system.rate = newVal-0
        //         },
        //         immediate: true
        //     }
        // }
    };
</script>
<style scoped></style>
<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .no-number::-webkit-outer-spin-button,
.no-number::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none !important;
}
 
.no-number input[type="number"]::-webkit-outer-spin-button,
.no-number input[type="number"]::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none !important;
}
 
/*在firefox下移除input[number]的上下箭头*/
.no-number {
  -moz-appearance: textfield;
}
 
.no-number input[type="number"] {
  -moz-appearance: textfield;
}
</style>